<template>
  <div>
    <div
      v-if="!hasYLHPermission"
      class="vh-100 bg-fff d-flex align-items-center flex-column justify-content-center"
    >
      <img src="../../assets/images/logining.png" alt="">
      <p class="mt-30">
        登录中，请耐心等待
      </p>
    </div>
    <div
      v-if="hasYLHPermission"
      class="vh-100 bg-fff d-flex align-items-center flex-column justify-content-center"
    >
      <img src="../../assets/images/no-permission.png" alt="">
      <p class="mt-30 mb-20 fs-24" style="color: #4D5B69">
        对不起，您还没有易理货权限
      </p>
      <p class="mt-30 fs-18 color-606266">
        请拨打
        <span style="color: #409EFF">400-8939-888</span>
        维护相应权限
      </p>
    </div>
    <remote-js :src="fingerprint2fScriptUrl" @load="loadScript"/>
  </div>
</template>

<script>
import { Default, V2Portal } from '../../apis'
import { systemInfo } from '@jsh/helper/utils'
import { clearToken } from '../../utils/auth'

export default {
  data () {
    return {
      deviceType: '', // 设备号
      deviceName: '', // 浏览器+版本号
      deviceNo: '', // 系统
      hasYLHPermission: false, // 是否有易理货权限
      fingerprint2fScriptUrl: `${location.origin}/home-resources/fingerprint2.min.js` // 获取设备号脚本地址
    }
  },
  methods: {
    loadScript () {
      const owner = this
      // 重定向到客户中心，则不需要再做其他处理
      if (this.$route.query.isRedirectToCC === '1') { return }
      if (
        systemInfo.shell !== 'none' &&
        systemInfo.shellVersion !== 'unknow'
      ) {
        this.deviceName =
          systemInfo.shell + ' ' + systemInfo.shellVersion
      } else {
        this.deviceName =
          systemInfo.browser + ' ' + systemInfo.browserVersion
      }
      setTimeout(() => {
        window.Fingerprint2.getV18({}, function (result) {
          owner.deviceNo = result
          localStorage.setItem('browserFingerprint', result)

          owner.login()
        })
      }, 500)
    },
    // 调清空客户中心登录信息的接口
    loginOut () {
      this.$cookie.delete('token')
      window.location.replace(
        `${process.env.VUE_APP_CUSTOMER_CENTER_HOST}/logout?redirect_uri=${location.origin}`
      )
    },

    login () {
      this.$http
        .get(V2Portal.customerCenterLogin, {
          code: this.$route.query.code
        })
        .then(res => {
          // 清理token
          clearToken()

          if (res.data.success) {
            localStorage.setItem('refreshToken', res.data.data.refreshToken)
            // 调易理货登录接口
            this.$http
              .postFormData(Default.LOGIN, {
                username: res.data.data.accountName,
                password: 'password',
                captNum: '',
                deviceNo: this.deviceNo,
                deviceName: this.deviceName,
                deviceType: this.deviceType
              })
              .then(res => {
                if (res.data.success) {
                  // 保存token到store中给ylhHelper用
                  localStorage.setItem('ylh_access_token', this.$cookie.get('token'))
                  // 客户中心用
                  window.localStorage.setItem('LoginState', 'true')
                  // 跳转首页
                  this.$router.push('/home')

                  this.$store.commit('changeActiveMenuId', 0)
                } else {
                  if (res.data.customerCenterErrorType === 1) {
                    // 没有易理货权限
                    this.hasYLHPermission = true
                  } else {
                    this.$cookie.delete('token')
                    this.$confirm(res.data.errorMsg, {
                      title: '登录异常',
                      showCancelButton: false,
                      showClose: false,
                      closeOnClickModal: false,
                      closeOnPressEscape: false,
                      center: true
                    }).then(() => {
                      this.loginOut()
                    })
                  }
                }
              })
              .catch(() => {
                this.$message.error('服务器异常')
              })
          } else {
            this.$confirm(res.data.errorMsg, {
              title: '登录异常',
              showCancelButton: false,
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              center: true
            }).then(() => {
              this.loginOut()
            })
          }
        })
        .catch(reason => {
          this.$message.error('服务器异常')
        })
    },

    isPC () {
      const userAgentInfo = navigator.userAgent
      const Agents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod'
      ]
      let flag = true
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false
          break
        }
      }
      return flag
    }
  },
  created () {
    localStorage.clear()

    this.hasYLHPermission = false

    const u = navigator.userAgent
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 // Android
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
    const isIpad = u.indexOf('iPad') > -1 // ipad

    // 是否为pc
    if (this.isPC()) {
      this.deviceType = (systemInfo.system && systemInfo.systemVersion) ? (systemInfo.system + ' ' + systemInfo.systemVersion) : '未知设备-pc'
    } else if (isIpad) {
      this.deviceType = 'ipad'
    } else if (isIOS) {
      this.deviceType = 'ios'
    } else if (isAndroid) {
      this.deviceType = 'Android'
    } else {
      this.deviceType = '未知设备' + u
    }

    if (this.$route.query.isRedirectToCC === '1') {
      // 重定向到登录页面
      this.$http
        .get(V2Portal.redirectToCC)
        .then(res => {
          if (res.data.success) {
            window.location.replace(res.data.data)
          } else {
            this.$message.error(res.data.errorMsg)
          }
        })
        .catch(() => {
          this.$message.error('服务器异常')
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
